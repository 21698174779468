
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { getResult, clearResults } from '@/services/results'
import Topbar from '@/components/Topbar.vue'
import TheButton from '@/components/TheButton.vue'
import { UserAnswer } from 'types/interface/ComposeQuestion'
import useResults from '@/composables/useResults'
import ProgressBar from '@/components/ProgressBar.vue'
import ResultsProgress from '@/components/ResultsProgress.vue'
import tenantConfig from '@/config/tenants/trafikk'
import { milisecondsToDate } from '@/utils/converters'
import useChapters from '@/composables/useChapters'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    Topbar,
    ResultsProgress,
    ProgressBar,
    TheButton,
  },
  props: {},

  setup() {
    const { tenant } = useTenant()
    const router = useRouter()
    const route = useRoute()
    const resultsList = ref([])
    const { numCorrect } = useResults()
    const { t } = useI18n()
    const { chapters, cleanProgressStatus } = useChapters()
    const { closeModal, setModal } = useModal()

    const isSuccess = (answers: UserAnswer[]): boolean => {
      return numCorrect(answers) >= tenantConfig.testLogic.passingScore
    }

    const progressTotal = computed(() => {
      const total = { lessonsCompleted: 0, lessonsNum: 0 }
      chapters.value.forEach((chapter) => {
        total.lessonsNum += chapter.lessonsNum || 0
        total.lessonsCompleted += chapter.lessonsCompleted || 0
      })
      return total
    })

    const showDetails = (resultsTimestamp: number) => {
      router.push({
        name: 'Results',
        params: { resultsTimestamp },
        query: { parent: route.name.toString() },
      })
    }

    useHead({
      title: computed(() => `${t('PROGRESS_HEADER')} - ${tenant.value.name}`),
    })

    onBeforeMount(async () => {
      getTestResults()
    })

    const clearProgress = (module: 'COURSE' | 'TEST') => {
      if (
        (module === 'TEST' && !resultsList.value.length) ||
        (module === 'COURSE' && !progressTotal.value.lessonsCompleted)
      ) {
        return
      }

      setModal('ModalAction', {
        title: `${t(`PROGRESS_CLEAR_${module}_HEADER`)}`,
        content: `${t(`PROGRESS_CLEAR_${module}_INFO`)}`,
        type: 'primary',
        actions: {
          confirm: {
            title: `${t(`PROGRESS_CLEAR_${module}_CONFIRM`)}`,
            type: 'primary-alt',
            on() {
              closeModal()
            },
          },
          cancel: {
            title: `${t(`PROGRESS_CLEAR_${module}_CANCEL`)}`,
            type: 'danger',
            async on() {
              module === 'COURSE'
                ? await cleanProgressStatus()
                : await clearTestProgress(),
                closeModal()
            },
          },
        },
      })
    }

    const clearTestProgress = async () => {
      await clearResults()
      await getTestResults()
    }

    const getTestResults = async () => {
      const resultsData = await getResult()

      resultsList.value = resultsData.attempts || []
    }

    const tippyItems = ref([])
    const tippyOnBlur = (tippyId: number) => {
      tippyItems.value[tippyId]?.hide()
    }

    return {
      chapters,
      resultsList,
      progressTotal,
      numCorrect,
      milisecondsToDate,
      isSuccess,
      t,
      showDetails,
      clearProgress,
      tippyItems,
      tippyOnBlur,
    }
  },
})
