<template>
  <Topbar back-url="/" back-icon="back" />
  <div class="v-progress">
    <h2 class="v-progress__heading">{{ $t('PROGRESS_HEADER') }}</h2>
    <div class="v-progress__description">{{ $t('PROGRESS_DESCRIPTION') }}</div>
    <div class="v-progress-course">
      <ResultsProgress
        :completed="progressTotal.lessonsCompleted"
        :total="progressTotal.lessonsNum"
        :title="$t('PROGRESS_TOTAL_LABEL')"
        class="v-progress-course__total"
      />
      <ResultsProgress
        v-for="chapter of chapters"
        :key="chapter.id"
        :completed="chapter.lessonsCompleted"
        :total="chapter.lessonsNum"
        :title="chapter.name"
        class="v-progress-course__chapter"
      />
    </div>
    <TheButton
      class="v-progress__clear-btn"
      type="primary"
      :disabled="!progressTotal.lessonsCompleted"
      @click="clearProgress('COURSE')"
    >
      {{ $t('PROGRESS_CLEAR_COURSE') }}
    </TheButton>
    <div class="v-progress-test">
      <h3 class="v-progress-test__heading">{{ $t('PROGRESS_TEST_HEADER') }}</h3>
      <div class="v-progress-test__container" v-if="resultsList">
        <div
          class="v-progress-test__result-wrapper"
          v-for="(res, resId) in resultsList"
          :key="res._id"
        >
          <tippy
            theme="trafikk"
            tabindex="0"
            :allowHTML="true"
            :interactive="true"
            :a11y="true"
            :trigger="`mouseenter focus`"
            :ref="(tippy) => (tippyItems[resId] = tippy)"
          >
            <div class="v-progress-test__result">
              <ProgressBar
                :total="res.results.length"
                :value="numCorrect(res.results)"
                :success="isSuccess(res.results)"
                vertical
              />
              <div class="v-progress-test__date">
                {{ milisecondsToDate(res.timestamp) }}
              </div>
            </div>
            <template #content>
              <div
                class="v-progress-test__tip-wrapper"
                @click="showDetails(res.timestamp)"
              >
                <div class="v-progress-test__tip-score">
                  {{ numCorrect(res.results) }}/{{ res.results.length }}
                </div>
                <button
                  class="v-progress-test__tip-link"
                  @click="showDetails(res.timestamp)"
                  @blur="tippyOnBlur(resId)"
                >
                  {{ $t('PROGRESS_SEE_RESULTS') }}
                </button>
              </div>
            </template>
          </tippy>
        </div>
      </div>
    </div>
    <TheButton
      class="v-progress__clear-btn"
      type="primary"
      :disabled="!resultsList.length"
      @click="clearProgress('TEST')"
    >
      {{ $t('PROGRESS_CLEAR_TEST') }}
    </TheButton>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { getResult, clearResults } from '@/services/results'
import Topbar from '@/components/Topbar.vue'
import TheButton from '@/components/TheButton.vue'
import { UserAnswer } from 'types/interface/ComposeQuestion'
import useResults from '@/composables/useResults'
import ProgressBar from '@/components/ProgressBar.vue'
import ResultsProgress from '@/components/ResultsProgress.vue'
import tenantConfig from '@/config/tenants/trafikk'
import { milisecondsToDate } from '@/utils/converters'
import useChapters from '@/composables/useChapters'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    Topbar,
    ResultsProgress,
    ProgressBar,
    TheButton,
  },
  props: {},

  setup() {
    const { tenant } = useTenant()
    const router = useRouter()
    const route = useRoute()
    const resultsList = ref([])
    const { numCorrect } = useResults()
    const { t } = useI18n()
    const { chapters, cleanProgressStatus } = useChapters()
    const { closeModal, setModal } = useModal()

    const isSuccess = (answers: UserAnswer[]): boolean => {
      return numCorrect(answers) >= tenantConfig.testLogic.passingScore
    }

    const progressTotal = computed(() => {
      const total = { lessonsCompleted: 0, lessonsNum: 0 }
      chapters.value.forEach((chapter) => {
        total.lessonsNum += chapter.lessonsNum || 0
        total.lessonsCompleted += chapter.lessonsCompleted || 0
      })
      return total
    })

    const showDetails = (resultsTimestamp: number) => {
      router.push({
        name: 'Results',
        params: { resultsTimestamp },
        query: { parent: route.name.toString() },
      })
    }

    useHead({
      title: computed(() => `${t('PROGRESS_HEADER')} - ${tenant.value.name}`),
    })

    onBeforeMount(async () => {
      getTestResults()
    })

    const clearProgress = (module: 'COURSE' | 'TEST') => {
      if (
        (module === 'TEST' && !resultsList.value.length) ||
        (module === 'COURSE' && !progressTotal.value.lessonsCompleted)
      ) {
        return
      }

      setModal('ModalAction', {
        title: `${t(`PROGRESS_CLEAR_${module}_HEADER`)}`,
        content: `${t(`PROGRESS_CLEAR_${module}_INFO`)}`,
        type: 'primary',
        actions: {
          confirm: {
            title: `${t(`PROGRESS_CLEAR_${module}_CONFIRM`)}`,
            type: 'primary-alt',
            on() {
              closeModal()
            },
          },
          cancel: {
            title: `${t(`PROGRESS_CLEAR_${module}_CANCEL`)}`,
            type: 'danger',
            async on() {
              module === 'COURSE'
                ? await cleanProgressStatus()
                : await clearTestProgress(),
                closeModal()
            },
          },
        },
      })
    }

    const clearTestProgress = async () => {
      await clearResults()
      await getTestResults()
    }

    const getTestResults = async () => {
      const resultsData = await getResult()

      resultsList.value = resultsData.attempts || []
    }

    const tippyItems = ref([])
    const tippyOnBlur = (tippyId: number) => {
      tippyItems.value[tippyId]?.hide()
    }

    return {
      chapters,
      resultsList,
      progressTotal,
      numCorrect,
      milisecondsToDate,
      isSuccess,
      t,
      showDetails,
      clearProgress,
      tippyItems,
      tippyOnBlur,
    }
  },
})
</script>

<style lang="scss">
.v-progress {
  padding: rem($topbar-height) 0 0;
  min-height: calc(100vh - $topbar-height);
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;
  &__heading {
    padding: 0 rem(32px);
    font-size: rem(32px);
    font-weight: 700;
    margin: rem(32px) 0 rem(8px);
  }
  &__description {
    padding-left: rem(32px);
  }
  &__clear-btn {
    max-width: 80vw;
    width: 20rem;
    margin: rem(48px) auto;
  }
}
.v-progress-course {
  padding: 0 rem(32px);
  margin: rem(56px) 0;

  &__total {
    padding-left: rem(16px);
    margin-bottom: rem(48px);
  }
  &__chapter {
    padding-left: rem(32px);
  }
}

.v-progress-test {
  &:before {
    content: '';
    top: rem($topbar-height);
    left: 0;
    position: absolute;
    min-height: calc(100vh - $topbar-height);
    width: rem(32px);
    pointer-events: none;
    z-index: 2;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &:after {
    content: '';
    top: rem($topbar-height);
    right: 0;
    position: absolute;
    min-height: calc(100vh - $topbar-height);
    width: rem(32px);
    pointer-events: none;
    z-index: 2;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 75%
    );
  }
  &__container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: rem(10px);
    height: rem(140px);
    padding: rem(10px);
    max-width: $app-max-width;
    margin: 0 auto;
    overflow-x: auto;
  }
  &__result {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: rem(8px);
    height: 100%;
  }
  &__date {
    font-size: rem(10px);
  }
  &__heading {
    padding-left: rem(48px);
    font-size: rem(16px);
    font-weight: 700;
    margin: rem(8px) 0;
  }
  &__tip-link {
    @include btnReset();
    @include a11yOutlineAlt();

    text-decoration: underline;
  }
}
</style>
