const milisecondsToTime = (ms: number, minutesOnly = false): string => {
  const minutes = Math.floor(ms / 60000)
  const seconds = Math.floor((ms % 60000) / 1000)
  if (minutesOnly) {
    return `${minutes}`
  }
  return `${twoDigits(minutes)}:${twoDigits(seconds)}`
}

const milisecondsToDate = (ms: number): string => {
  const date = new Date(ms)
  return `${twoDigits(date.getDate())}/${twoDigits(date.getMonth() + 1)}`
}

function twoDigits(n: number) {
  return n < 10 ? '0' + n : n
}

export { milisecondsToTime, milisecondsToDate }
