<template>
  <div class="c-result-progress">
    <div class="c-result-progress--title">
      {{ title }}
    </div>
    <ProgressBar
      class="c-result-progress--bar"
      :total="total"
      :value="completed"
      :success="true"
    />
    <div class="c-result-progress--info">
      {{ $t('PROGRESS_INFO', [completed, total]) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProgressBar from '@/components/ProgressBar.vue'

export default defineComponent({
  name: 'ResultsProgress',
  components: {
    ProgressBar,
  },
  props: {
    completed: Number,
    total: Number,
    title: String,
  },
})
</script>

<style lang="scss">
.c-result-progress {
  color: $progress-card-text;
  margin: rem(24px) 0;
  > div {
    margin: rem(8px) 0;
  }

  &--title {
    font-weight: bold;
  }
  &--bar {
    max-width: 200px;
  }
}
</style>
