
import { defineComponent } from 'vue'
import ProgressBar from '@/components/ProgressBar.vue'

export default defineComponent({
  name: 'ResultsProgress',
  components: {
    ProgressBar,
  },
  props: {
    completed: Number,
    total: Number,
    title: String,
  },
})
