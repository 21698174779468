
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: Number,
    total: Number,
    success: Boolean,
    vertical: Boolean,
  },
})
