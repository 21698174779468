import { UserAnswer, ComposeResults } from 'types/interface/ComposeQuestion'
import { getResult } from '@/services/results'

const getUserAnswers = async (
  resultsTimestamp: number,
): Promise<UserAnswer[]> => {
  const res = await getResult()

  return res.attempts.filter(
    (attempt) => attempt.timestamp === resultsTimestamp,
  )[0].results
}

const numCorrect = (userAnswers: UserAnswer[]): number => {
  return userAnswers.filter((userAnswer) => userAnswer.answer?.correct).length
}

export default (): ComposeResults => ({
  getUserAnswers,
  numCorrect,
})
