<template>
  <div
    class="v-progress-bar"
    :class="[
      vertical ? 'v-progress-bar__vertical' : 'v-progress-bar__horizontal',
    ]"
  >
    <div
      class="v-progress-bar__value"
      :class="[
        success
          ? 'v-progress-bar__value-success'
          : 'v-progress-bar__value-fail',
      ]"
      :style="{
        width: vertical ? '100%' : `${(100 * value) / total}%`,
        height: !vertical ? '100%' : `${(100 * value) / total}%`,
      }"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: Number,
    total: Number,
    success: Boolean,
    vertical: Boolean,
  },
})
</script>

<style lang="scss">
$progress-bar-size: rem(8px);
.v-progress-bar {
  position: relative;
  background-color: $progress-bar-bcg;
  border-radius: $progress-bar-size;
  overflow: hidden;

  &__horizontal {
    max-height: $progress-bar-size;
    height: $progress-bar-size;
    width: 100%;
  }

  &__vertical {
    width: $progress-bar-size;
    max-width: $progress-bar-size;
    height: 100%;
    .v-progress-bar__value {
      bottom: 0;
    }
  }

  &__value {
    position: absolute;
    border-radius: $progress-bar-size;

    &-success {
      background-color: $progress-bar-success;
    }

    &-fail {
      background-color: $progress-bar-fail;
    }
  }
}
</style>
